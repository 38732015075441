
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">

          <Container>
            <Row className="row-grid align-items-center my-md">
              <Col lg="6">

                <h4 className="mb-0 font-weight-light">
                  Stay Connected. Find Predlab on these mediums
                </h4>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/predlab"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow Predlab
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="warning"
                  href="https://www.reddit.com/r/Predlab/"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-reddit-alien" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Join the community
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href="https://medium.com/@predlab/"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-medium" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Read Predlab-related stories.
                </UncontrolledTooltip>

              </Col>
            </Row>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6" sm="12">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                    Predlab
                </div>
              </Col>
              <Col md="6" sm="12">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="https://predlab.com/guide"
                      target="_blank"
                    >
                      Predlab Guide
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://plugins.jetbrains.com/plugin/13568-predlab"
                      target="_blank"
                    >
                      Plugin Repository
                    </NavLink>
                  </NavItem>

                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
