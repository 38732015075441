
import React from "react";

// reactstrap components
import {Button, Card, Container, Row, Col, CardBody, Badge} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import PredNavbar from "../components/Navbars/PredNavbar";
import PredFooter from "../components/Footers/PredFooter";

class Pricing extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;

        document.title = "Pricing - Predlab"
    }

    render() {
        return (
            <>
                <PredNavbar/>
                <main className="profile-page" ref="main">
                    <section className="section-profile-cover section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                        </div>
                        <Container className="py-lg-md d-flex pricing-titles-container">
                            <div className="col px-0">

                                <Row className="justify-content-center text-center">
                                    <Col lg="8">
                                        <h2 className="display-3 text-white">Adapted for Your Team</h2>
                                        <p className="lead text-muted text-white">
                                            Start documenting differently with Predlab and only pay for what you use.
                                            All plans include the free tier. Upgrade any time if you want more freedom!
                                        </p>
                                    </Col>
                                </Row>
                            </div>


                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">

                        </div>
                    </section>
                    <section className="section section-lg pt-lg-0 mt--200">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <Row className="row-grid">
                                        <Col lg="4">
                                            <Card className="card-lift--hover shadow border-0">
                                                <CardBody className="py-5">
                                                    <div className="justify-content-center text-center">
                                                        <div
                                                            className="icon icon-shape icon-shape-primary rounded-circle mb-2">
                                                            <i className="ni ni-check-bold"/>
                                                        </div>

                                                        <h6 className="display-4 text-uppercase">
                                                            Free Tier
                                                        </h6>
                                                        <p className="description mb-4">
                                                            Starter Plan for small teams and solo developers
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <img style={{marginRight: "5px"}} height="16px"
                                                             src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NzcuNDMxLDE1OS4zMjRDNDUzLjkwMiwxMzIuNjc2LDQyMC43MjIsMTE4LDM4NCwxMThjLTQ1LjI4MiwwLTc5LjM2OCwyNi4wODUtMTAzLjI1Myw1NS4wMjMgICAgYzkuMDc2LDEzLjkzOCwxNi4xNDcsMjcuMjgsMjEuMzAzLDM4LjEwNkMzMjAuMzk3LDE4NC43MDEsMzQ3Ljg3MiwxNTgsMzg0LDE1OGM1MC45OTEsMCw4OCw0MS4yMTUsODgsOTggICAgYzAsNTYuNzg1LTM3LjAwOSw5OC04OCw5OGMtNzIuODk1LDAtMTA4LjY5MS0xMDMuMzM0LTEwOS4wMzQtMTA0LjM0OWwtMC4wOTktMC4yODdDMjcyLjk4MSwyNDQsMjI3LjUyMiwxMTgsMTI4LDExOCAgICBjLTM2LjcyMiwwLTY5LjkwMiwxNC42NzYtOTMuNDMxLDQxLjMyNEMxMi4yNzcsMTg0LjU3MiwwLDIxOC45MDYsMCwyNTZzMTIuMjc3LDcxLjQyOCwzNC41NjksOTYuNjc2ICAgIEM1OC4wOTgsMzc5LjMyNCw5MS4yNzgsMzk0LDEyOCwzOTRjNDUuNjU0LDAsNzkuNTY2LTI1LjQzLDEwMy4yNDQtNTMuOTUxYy05LjA1NC0xMy45MzUtMTYuMDY1LTI3LjI5My0yMS4yMTEtMzguMjk0ICAgIEMxOTEuOTI5LDMyNy44NjksMTY0LjY1NywzNTQsMTI4LDM1NGMtNTAuOTkxLDAtODgtNDEuMjE1LTg4LTk4YzAtNTYuNzg1LDM3LjAwOS05OCw4OC05OCAgICBjNzAuMzUzLDAsMTA3LjkwOCwxMDEuMjY1LDEwOS4xMDYsMTA0LjU2MUMyMzkuNzYzLDI3MC4zOTMsMjgzLjM1NCwzOTQsMzg0LDM5NGMzNi43MjIsMCw2OS45MDItMTQuNjc2LDkzLjQzMS00MS4zMjQgICAgQzQ5OS43MjMsMzI3LjQyOCw1MTIsMjkzLjA5NCw1MTIsMjU2UzQ5OS43MjMsMTg0LjU3Miw0NzcuNDMxLDE1OS4zMjR6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="/>
                                                        <label>
                                                            <span> Unlimited Workspaces</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <img style={{marginRight: "5px"}} height="16px"
                                                             src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NzcuNDMxLDE1OS4zMjRDNDUzLjkwMiwxMzIuNjc2LDQyMC43MjIsMTE4LDM4NCwxMThjLTQ1LjI4MiwwLTc5LjM2OCwyNi4wODUtMTAzLjI1Myw1NS4wMjMgICAgYzkuMDc2LDEzLjkzOCwxNi4xNDcsMjcuMjgsMjEuMzAzLDM4LjEwNkMzMjAuMzk3LDE4NC43MDEsMzQ3Ljg3MiwxNTgsMzg0LDE1OGM1MC45OTEsMCw4OCw0MS4yMTUsODgsOTggICAgYzAsNTYuNzg1LTM3LjAwOSw5OC04OCw5OGMtNzIuODk1LDAtMTA4LjY5MS0xMDMuMzM0LTEwOS4wMzQtMTA0LjM0OWwtMC4wOTktMC4yODdDMjcyLjk4MSwyNDQsMjI3LjUyMiwxMTgsMTI4LDExOCAgICBjLTM2LjcyMiwwLTY5LjkwMiwxNC42NzYtOTMuNDMxLDQxLjMyNEMxMi4yNzcsMTg0LjU3MiwwLDIxOC45MDYsMCwyNTZzMTIuMjc3LDcxLjQyOCwzNC41NjksOTYuNjc2ICAgIEM1OC4wOTgsMzc5LjMyNCw5MS4yNzgsMzk0LDEyOCwzOTRjNDUuNjU0LDAsNzkuNTY2LTI1LjQzLDEwMy4yNDQtNTMuOTUxYy05LjA1NC0xMy45MzUtMTYuMDY1LTI3LjI5My0yMS4yMTEtMzguMjk0ICAgIEMxOTEuOTI5LDMyNy44NjksMTY0LjY1NywzNTQsMTI4LDM1NGMtNTAuOTkxLDAtODgtNDEuMjE1LTg4LTk4YzAtNTYuNzg1LDM3LjAwOS05OCw4OC05OCAgICBjNzAuMzUzLDAsMTA3LjkwOCwxMDEuMjY1LDEwOS4xMDYsMTA0LjU2MUMyMzkuNzYzLDI3MC4zOTMsMjgzLjM1NCwzOTQsMzg0LDM5NGMzNi43MjIsMCw2OS45MDItMTQuNjc2LDkzLjQzMS00MS4zMjQgICAgQzQ5OS43MjMsMzI3LjQyOCw1MTIsMjkzLjA5NCw1MTIsMjU2UzQ5OS43MjMsMTg0LjU3Miw0NzcuNDMxLDE1OS4zMjR6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="/>
                                                        <label>
                                                            <span> Unlimited Offline Workspaces</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-check" aria-hidden="true"/> <label>
                                                        <span> Up to 3 collaborators/workspace</span>
                                                    </label>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-check" aria-hidden="true"/> <label>
                                                        <span> 1 backup per workspace/month</span>
                                                    </label>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-check" aria-hidden="true"/> <label>
                                                        <span> Access to Web Console</span>
                                                    </label>
                                                    </div>
                                                    <div className="mb-3">
                                                        <i className="fa fa-check" aria-hidden="true"/> <label>
                                                        <span> Predlab Support Center</span>
                                                    </label>
                                                    </div>

                                                    <Row className="justify-content-center text-center">
                                                        <Col lg="12">
                                                            {/*<span className="display-4">$0</span><small>/month</small>*/}
                                                            <span className="display-4">FREE</span>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4">
                                            <Card className="card-lift--hover shadow border-0">
                                                <Badge color="success" style={{borderRadius: "0"}}>
                                                    LIMITED TIME
                                                </Badge>
                                                <CardBody className="py-5">
                                                    <div className="justify-content-center text-center">
                                                        <div
                                                            className="icon icon-shape icon-shape-success rounded-circle mb-2">
                                                            <i className="fa fa-first-order"/>
                                                        </div>

                                                        <h6 className="text-success display-4 text-uppercase">
                                                            Early Bird
                                                        </h6>
                                                        <p className="description mb-4">
                                                            Limited Time Only Plan with more freedom for early birds!
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <img style={{marginRight: "5px"}} height="16px"
                                                             src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NzcuNDMxLDE1OS4zMjRDNDUzLjkwMiwxMzIuNjc2LDQyMC43MjIsMTE4LDM4NCwxMThjLTQ1LjI4MiwwLTc5LjM2OCwyNi4wODUtMTAzLjI1Myw1NS4wMjMgICAgYzkuMDc2LDEzLjkzOCwxNi4xNDcsMjcuMjgsMjEuMzAzLDM4LjEwNkMzMjAuMzk3LDE4NC43MDEsMzQ3Ljg3MiwxNTgsMzg0LDE1OGM1MC45OTEsMCw4OCw0MS4yMTUsODgsOTggICAgYzAsNTYuNzg1LTM3LjAwOSw5OC04OCw5OGMtNzIuODk1LDAtMTA4LjY5MS0xMDMuMzM0LTEwOS4wMzQtMTA0LjM0OWwtMC4wOTktMC4yODdDMjcyLjk4MSwyNDQsMjI3LjUyMiwxMTgsMTI4LDExOCAgICBjLTM2LjcyMiwwLTY5LjkwMiwxNC42NzYtOTMuNDMxLDQxLjMyNEMxMi4yNzcsMTg0LjU3MiwwLDIxOC45MDYsMCwyNTZzMTIuMjc3LDcxLjQyOCwzNC41NjksOTYuNjc2ICAgIEM1OC4wOTgsMzc5LjMyNCw5MS4yNzgsMzk0LDEyOCwzOTRjNDUuNjU0LDAsNzkuNTY2LTI1LjQzLDEwMy4yNDQtNTMuOTUxYy05LjA1NC0xMy45MzUtMTYuMDY1LTI3LjI5My0yMS4yMTEtMzguMjk0ICAgIEMxOTEuOTI5LDMyNy44NjksMTY0LjY1NywzNTQsMTI4LDM1NGMtNTAuOTkxLDAtODgtNDEuMjE1LTg4LTk4YzAtNTYuNzg1LDM3LjAwOS05OCw4OC05OCAgICBjNzAuMzUzLDAsMTA3LjkwOCwxMDEuMjY1LDEwOS4xMDYsMTA0LjU2MUMyMzkuNzYzLDI3MC4zOTMsMjgzLjM1NCwzOTQsMzg0LDM5NGMzNi43MjIsMCw2OS45MDItMTQuNjc2LDkzLjQzMS00MS4zMjQgICAgQzQ5OS43MjMsMzI3LjQyOCw1MTIsMjkzLjA5NCw1MTIsMjU2UzQ5OS43MjMsMTg0LjU3Miw0NzcuNDMxLDE1OS4zMjR6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="/>
                                                        <label>
                                                            <span> Unlimited Workspaces</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <img style={{marginRight: "5px"}} height="16px"
                                                             src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NzcuNDMxLDE1OS4zMjRDNDUzLjkwMiwxMzIuNjc2LDQyMC43MjIsMTE4LDM4NCwxMThjLTQ1LjI4MiwwLTc5LjM2OCwyNi4wODUtMTAzLjI1Myw1NS4wMjMgICAgYzkuMDc2LDEzLjkzOCwxNi4xNDcsMjcuMjgsMjEuMzAzLDM4LjEwNkMzMjAuMzk3LDE4NC43MDEsMzQ3Ljg3MiwxNTgsMzg0LDE1OGM1MC45OTEsMCw4OCw0MS4yMTUsODgsOTggICAgYzAsNTYuNzg1LTM3LjAwOSw5OC04OCw5OGMtNzIuODk1LDAtMTA4LjY5MS0xMDMuMzM0LTEwOS4wMzQtMTA0LjM0OWwtMC4wOTktMC4yODdDMjcyLjk4MSwyNDQsMjI3LjUyMiwxMTgsMTI4LDExOCAgICBjLTM2LjcyMiwwLTY5LjkwMiwxNC42NzYtOTMuNDMxLDQxLjMyNEMxMi4yNzcsMTg0LjU3MiwwLDIxOC45MDYsMCwyNTZzMTIuMjc3LDcxLjQyOCwzNC41NjksOTYuNjc2ICAgIEM1OC4wOTgsMzc5LjMyNCw5MS4yNzgsMzk0LDEyOCwzOTRjNDUuNjU0LDAsNzkuNTY2LTI1LjQzLDEwMy4yNDQtNTMuOTUxYy05LjA1NC0xMy45MzUtMTYuMDY1LTI3LjI5My0yMS4yMTEtMzguMjk0ICAgIEMxOTEuOTI5LDMyNy44NjksMTY0LjY1NywzNTQsMTI4LDM1NGMtNTAuOTkxLDAtODgtNDEuMjE1LTg4LTk4YzAtNTYuNzg1LDM3LjAwOS05OCw4OC05OCAgICBjNzAuMzUzLDAsMTA3LjkwOCwxMDEuMjY1LDEwOS4xMDYsMTA0LjU2MUMyMzkuNzYzLDI3MC4zOTMsMjgzLjM1NCwzOTQsMzg0LDM5NGMzNi43MjIsMCw2OS45MDItMTQuNjc2LDkzLjQzMS00MS4zMjQgICAgQzQ5OS43MjMsMzI3LjQyOCw1MTIsMjkzLjA5NCw1MTIsMjU2UzQ5OS43MjMsMTg0LjU3Miw0NzcuNDMxLDE1OS4zMjR6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="/>
                                                        <label>
                                                            <span> Unlimited Offline Workspaces</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <i className="text-success fa fa-check" aria-hidden="true"/>
                                                         <label>
                                                            <span className="text-success ml-1"> 5 collaborators per workspace</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-check text-success" aria-hidden="true"/>
                                                         <label>
                                                            <span className="text-success ml-1"> 2 backups per workspace/month</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-check" aria-hidden="true"/> <label>
                                                        <span> Access to Web Console</span>
                                                    </label>
                                                    </div>
                                                    <div className="mb-3">
                                                        <i className="fa fa-check" aria-hidden="true"/> <label>
                                                        <span> Predlab Support Center</span>
                                                    </label>
                                                    </div>
                                                    <div className="mb-3">
                                                        <i className="text-success fa fa-gift" aria-hidden="true"/>
                                                        <label>
                                                            <span
                                                                className="text-success ml-1"> Freebies with new features.</span>
                                                        </label>
                                                    </div>

                                                    <Row className="justify-content-center text-center">
                                                        <Col lg="12">
                                                            {/*<s>Value of $14</s>*/}
                                                            <span className="display-4">FREE</span>
                                                            {/*<span className="display-4">FREE</span>*/}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4">
                                            <Card className="card-lift--hover shadow border-0">
                                                <CardBody className="py-5">
                                                    <div className="justify-content-center text-center">
                                                        <div
                                                            className="icon icon-shape icon-shape-warning rounded-circle mb-2">
                                                            <i className="fa fa-rocket"/>
                                                        </div>

                                                        <h6 className="display-4 text-uppercase text-warning">
                                                            Standard
                                                        </h6>
                                                        <p className="description mb-4">
                                                            Pay-as-you go plan for Founders!
                                                        </p>
                                                    </div>

                                                    <div className="mb-4">
                                                        <i className="fa fa-check text-warning" aria-hidden="true"/>
                                                        <label>
                                                            <span className="text-warning ml-1">Everything in the Free Tier Plan</span>
                                                        </label>
                                                    </div>

                                                    <div>
                                                        <Row className="justify-content-center text-center mb-4">
                                                            <span>Above that</span>
                                                        </Row>
                                                    </div>
                                                    {/*<div>*/}
                                                    {/*    <img  style={{marginRight:"5px"}} height="16px" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NzcuNDMxLDE1OS4zMjRDNDUzLjkwMiwxMzIuNjc2LDQyMC43MjIsMTE4LDM4NCwxMThjLTQ1LjI4MiwwLTc5LjM2OCwyNi4wODUtMTAzLjI1Myw1NS4wMjMgICAgYzkuMDc2LDEzLjkzOCwxNi4xNDcsMjcuMjgsMjEuMzAzLDM4LjEwNkMzMjAuMzk3LDE4NC43MDEsMzQ3Ljg3MiwxNTgsMzg0LDE1OGM1MC45OTEsMCw4OCw0MS4yMTUsODgsOTggICAgYzAsNTYuNzg1LTM3LjAwOSw5OC04OCw5OGMtNzIuODk1LDAtMTA4LjY5MS0xMDMuMzM0LTEwOS4wMzQtMTA0LjM0OWwtMC4wOTktMC4yODdDMjcyLjk4MSwyNDQsMjI3LjUyMiwxMTgsMTI4LDExOCAgICBjLTM2LjcyMiwwLTY5LjkwMiwxNC42NzYtOTMuNDMxLDQxLjMyNEMxMi4yNzcsMTg0LjU3MiwwLDIxOC45MDYsMCwyNTZzMTIuMjc3LDcxLjQyOCwzNC41NjksOTYuNjc2ICAgIEM1OC4wOTgsMzc5LjMyNCw5MS4yNzgsMzk0LDEyOCwzOTRjNDUuNjU0LDAsNzkuNTY2LTI1LjQzLDEwMy4yNDQtNTMuOTUxYy05LjA1NC0xMy45MzUtMTYuMDY1LTI3LjI5My0yMS4yMTEtMzguMjk0ICAgIEMxOTEuOTI5LDMyNy44NjksMTY0LjY1NywzNTQsMTI4LDM1NGMtNTAuOTkxLDAtODgtNDEuMjE1LTg4LTk4YzAtNTYuNzg1LDM3LjAwOS05OCw4OC05OCAgICBjNzAuMzUzLDAsMTA3LjkwOCwxMDEuMjY1LDEwOS4xMDYsMTA0LjU2MUMyMzkuNzYzLDI3MC4zOTMsMjgzLjM1NCwzOTQsMzg0LDM5NGMzNi43MjIsMCw2OS45MDItMTQuNjc2LDkzLjQzMS00MS4zMjQgICAgQzQ5OS43MjMsMzI3LjQyOCw1MTIsMjkzLjA5NCw1MTIsMjU2UzQ5OS43MjMsMTg0LjU3Miw0NzcuNDMxLDE1OS4zMjR6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />*/}
                                                    {/*    <label >*/}
                                                    {/*        <span> Unlimited collaborators*</span>*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                    {/*<div>*/}
                                                    {/*    <img  style={{marginRight:"5px"}} height="16px" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NzcuNDMxLDE1OS4zMjRDNDUzLjkwMiwxMzIuNjc2LDQyMC43MjIsMTE4LDM4NCwxMThjLTQ1LjI4MiwwLTc5LjM2OCwyNi4wODUtMTAzLjI1Myw1NS4wMjMgICAgYzkuMDc2LDEzLjkzOCwxNi4xNDcsMjcuMjgsMjEuMzAzLDM4LjEwNkMzMjAuMzk3LDE4NC43MDEsMzQ3Ljg3MiwxNTgsMzg0LDE1OGM1MC45OTEsMCw4OCw0MS4yMTUsODgsOTggICAgYzAsNTYuNzg1LTM3LjAwOSw5OC04OCw5OGMtNzIuODk1LDAtMTA4LjY5MS0xMDMuMzM0LTEwOS4wMzQtMTA0LjM0OWwtMC4wOTktMC4yODdDMjcyLjk4MSwyNDQsMjI3LjUyMiwxMTgsMTI4LDExOCAgICBjLTM2LjcyMiwwLTY5LjkwMiwxNC42NzYtOTMuNDMxLDQxLjMyNEMxMi4yNzcsMTg0LjU3MiwwLDIxOC45MDYsMCwyNTZzMTIuMjc3LDcxLjQyOCwzNC41NjksOTYuNjc2ICAgIEM1OC4wOTgsMzc5LjMyNCw5MS4yNzgsMzk0LDEyOCwzOTRjNDUuNjU0LDAsNzkuNTY2LTI1LjQzLDEwMy4yNDQtNTMuOTUxYy05LjA1NC0xMy45MzUtMTYuMDY1LTI3LjI5My0yMS4yMTEtMzguMjk0ICAgIEMxOTEuOTI5LDMyNy44NjksMTY0LjY1NywzNTQsMTI4LDM1NGMtNTAuOTkxLDAtODgtNDEuMjE1LTg4LTk4YzAtNTYuNzg1LDM3LjAwOS05OCw4OC05OCAgICBjNzAuMzUzLDAsMTA3LjkwOCwxMDEuMjY1LDEwOS4xMDYsMTA0LjU2MUMyMzkuNzYzLDI3MC4zOTMsMjgzLjM1NCwzOTQsMzg0LDM5NGMzNi43MjIsMCw2OS45MDItMTQuNjc2LDkzLjQzMS00MS4zMjQgICAgQzQ5OS43MjMsMzI3LjQyOCw1MTIsMjkzLjA5NCw1MTIsMjU2UzQ5OS43MjMsMTg0LjU3Miw0NzcuNDMxLDE1OS4zMjR6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />*/}
                                                    {/*    <label >*/}
                                                    {/*        <span> Unlimited workspace backups**</span>*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                    <div>
                                                        <label>
                                                            <span> <span className="display-4">$7</span> per extra collaborator/month</span>
                                                        </label>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label>
                                                            <span><span className="display-4">$2</span> per extra workspace backup/month</span>
                                                        </label>
                                                    </div>

                                                    <Row className="justify-content-center text-center">
                                                        <Col lg="12">

                                                            <span className="display-4">Pay as you go</span>
                                                            {/*  <span className="display-4">FREE</span>*/}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <PredFooter/>
            </>
        );
    }
}

export default Pricing;
