import React, {useEffect, useRef, useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, UncontrolledCarousel, UncontrolledPopover, PopoverHeader, PopoverBody
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "./IndexSections/Download.js";
import PredNavbar from "../components/Navbars/PredNavbar";
import Carousel from "./IndexSections/Carousel";
import PredFooter from "../components/Footers/PredFooter";

const LandingPredlab = (props) => {

    const [isFlagPreviewVisible, setIsFlagPreviewVisible] = useState(false);

    const refs = React.createRef()
    // const ref = React.createRef()
    const ref = useRef(null)

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        window.scrollTo(0, 0)

        document.title = props.title || "Real Time Documentation - Predlab"
    }, []);


    const showFlagPreview = () => {
        setIsFlagPreviewVisible(true)
    }

    const hideFlagPreview = () => {
        setIsFlagPreviewVisible(false)
    }

    const items = [
        {
            src: require("assets/img/mockup_browser.png"),
            altText: "",
            caption: "",
            header: ""
        },
        {
            src: require("assets/img/mockup_browser_2.png"),
            altText: "",
            caption: "",
            header: ""
        }
    ];

    return (
        <>
            <PredNavbar/>
            <main ref={ref}>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-lg section-shaped">
                        <div className="shape shape-style-1 shape-default">
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="9">
                                        <h1 className="display-3 text-white">
                                            IDE.. reimagined{" "}
                                            <span>Predlab for the IntelliJ IDE suite</span>
                                            {/*<span>Predlab for the IntelliJ IDE suite</span>*/}
                                        </h1>
                                        {/*<p className="lead text-white">*/}
                                        {/*  The design system comes with four pre-built pages to*/}
                                        {/*  help you get started faster. You can change the text and*/}
                                        {/*  images and you're good to go.*/}
                                        {/*</p>*/}
                                        <div className="btn-wrapper">
                                        </div>
                                    </Col>
                                </Row>
                            </div>


                        </Container>
                        {/* SVG separator */}

                        <section className="section section-lg pt-lg-0 interactive-section">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col lg="12">
                                        <div className="shape shape-style-1 shape-default" style={{zIndex: "0"}}>
                                            <Button
                                                className="rounded-circle btn-icon-only rounded-circle interactive-section-bubble"
                                                color="success"
                                                id="flag-bubble"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                            > <i className="fa fa-flag-o"/>
                                            </Button>
                                            <UncontrolledPopover
                                                trigger="focus"
                                                placement="right"
                                                target="flag-bubble"
                                            >
                                                <PopoverHeader>Codeflags</PopoverHeader>
                                                <PopoverBody>
                                                    Predlab let's you annotate your code dynamically and with better content.
                                                </PopoverBody>
                                            </UncontrolledPopover>

                                            <Button
                                                className="btn-icon-only rounded-circle interactive-section-bubble"
                                                color="success"
                                                id="people-bubble"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                            > <i className="fa fa-users"/>
                                            </Button>

                                            <UncontrolledPopover
                                                trigger="focus"
                                                placement="left"
                                                target="people-bubble"
                                            >
                                                <PopoverHeader>Team Mates</PopoverHeader>
                                                <PopoverBody>
                                                    Predlab Turns your project into a workspace where you collaborate with your team mates.
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                            <Button
                                                className="shadow--hover btn-icon-only rounded-circle interactive-section-bubble"
                                                color="success"
                                                id="feed-bubble"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                            > <i className="fa fa-rss"/>
                                            </Button>
                                            <UncontrolledPopover
                                                trigger="focus"
                                                placement="left"
                                                target="feed-bubble"
                                            >
                                                <PopoverHeader>Real-time feed</PopoverHeader>
                                                <PopoverBody>
                                                    Team members have instant access to annotations updates and activity!
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                            <img
                                                alt="..."
                                                className="screen-interactive img-fluid"
                                                src={require("assets/img/screen_ide_main.png")}
                                            />
                                        </div>


                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                </div>

                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="img-fluid rounded shadow-lg order-md-2 floating-flag-container" md="6">
                                <img
                                    alt="..."
                                    onMouseOver={showFlagPreview}
                                    onMouseLeave={hideFlagPreview}
                                    className="img-fluid floating img-codeflag-cropped"
                                    src={require("assets/img/flag_cropped.png")}
                                />
                                {isFlagPreviewVisible
                                    ? <img
                                        alt="..."
                                        className="img-fluid shadow--hover img-codeflag-preview-popup"
                                        src={require("assets/img/screen_codeflag_preview_click.png")}
                                    />
                                    : null}
                                <small className="text-hint text-muted">Hover with your mouse to simulate the
                                    IDE</small>

                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <div
                                        className="icon icon-lg icon-shape shadow rounded-circle mb-5">
                                        <i className="fa fa-flag-o"/>
                                    </div>
                                    <h3>Meet the Codeflags</h3>
                                    <p>
                                        An image is worth a thousand words. With Predlab, you can annotate your code
                                        with images, links, markdown, and many third-party services.
                                    </p>
                                    <ul className="list-unstyled mt-5">

                                        {/*<li className="py-2">*/}
                                        {/*    <div className="d-flex align-items-center">*/}
                                        {/*        <div>*/}
                                        {/*            <Badge*/}
                                        {/*                className="badge-circle mr-3"*/}
                                        {/*                color="success"*/}
                                        {/*            >*/}
                                        {/*                <i className="fa fa-font"/>*/}
                                        {/*            </Badge>*/}
                                        {/*        </div>*/}
                                        {/*        <div>*/}
                                        {/*            <h6 className="mb-0">Rich content</h6>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}
                                        <a
                                            className="font-weight-bold mt-5"
                                            href="https://predlab.com/guide/code-annotations/"
                                            target="_blank"
                                        >
                                            Learn More
                                        </a>

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-secondary">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                {/*<Card className="bg-default shadow border-0">*/}
                                {/*    <CardImg*/}
                                {/*        alt="..."*/}
                                {/*        src={require("assets/img/screen_people.png")}*/}
                                {/*        top*/}
                                {/*    />*/}

                                {/*</Card>*/}

                                <img
                                    alt="..."

                                    className="img-fluid   rounded shadow-lg"
                                    src={require("assets/img/screen_people.png")}
                                />
                            </Col>
                            <Col md="6">
                                <div className="pl-md-5">
                                    <div className="icon icon-lg icon-shape  shadow rounded-circle mb-5">
                                        <i className="fa fa-users"/>
                                    </div>
                                    <h3>Team-centered Workspaces</h3>
                                    <p className="lead">
                                        Workspaces are private places where team members collaborate, access, and share
                                        annotations.
                                    </p>
                                    <ul className="list-unstyled mt-5">

                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="success"
                                                    >
                                                        <i className="fa fa-cloud"/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        Real Time
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="success"
                                                    >
                                                        <i className="fa fa-user-secret"/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        Private
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                    <a
                                        className="font-weight-bold mt-5"
                                        href="https://predlab.com/guide/workspaces/"
                                        target="_blank"
                                    >
                                        Learn More
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className=" order-md-2" md="6">
                                <img
                                    alt="..."

                                    className="img-fluid   rounded shadow-lg"
                                    src={require("assets/img/screen_ghost_flag.png")}
                                />


                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <div
                                        className="icon icon-lg icon-shape shadow rounded-circle mb-5">
                                        <i className="fa fa-rss"/>
                                    </div>
                                    <h3>Real Time Activity Feed</h3>
                                    <p>
                                        New annotations as well as changes made on them are available instantly to all
                                        workspace members.
                                    </p>
                                    <p>
                                        No extra commits, no need to pull changes just to update the documentation.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section pb-0 bg-gradient-light pb-5">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-lg-2 ml-lg-auto" md="6">
                                <div className="position-relative pl-md-5">
                                    <img
                                        alt="..."
                                        style={{marginBottom: "30px"}}
                                        className="img-center img-fluid shadow-lg"
                                        src={require("assets/img/screen_sandbox_mode.png")}
                                    />
                                </div>
                            </Col>
                            <Col className="order-lg-1" lg="6">
                                <div className="d-flex px-3">
                                    <div>
                                        <div
                                            className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            {/*<i className="ni ni-building text-primary" />*/}
                                            <img
                                                alt="..."
                                                className="img-center img-fluid"
                                                src={require("assets/img/icons/incognito.svg")}
                                            />
                                        </div>
                                    </div>
                                    <div className="pl-4">
                                        <h4 className="display-3 text-white">Sandbox Mode</h4>
                                        <p className="text-white">
                                            An offline mode, where the annotations are saved locally in your project’s
                                            directory.
                                            The annotations added in an Offline workspace are not synced with Predlab.
                                        </p>
                                        <a
                                            href="https://predlab.com/guide/sandbox-mode/"
                                            target="_blank"
                                            className="btn btn-white btn-icon"
                                            color="default"
                                        >Learn More</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>


                <section className="section bg-gradient-warning">

                    <Container className="py-md">
                        <Row className="justify-content-between align-items-center">

                            <Col className="mb-lg-auto pb-5" lg="6">

                                {/*<img*/}
                                {/*    alt="..."*/}
                                {/*    style={{borderTopLeftRadius: "0.25rem"}}*/}
                                {/*    className="img-fluid shadow-lg"*/}
                                {/*    src={require("assets/img/mockup_browser.jpg")}*/}
                                {/*/>*/}
                                <div className="rounded shadow-lg overflow-hidden ">
                                    <UncontrolledCarousel items={items}/>
                                </div>
                            </Col>

                            <Col className="mb-5 mb-lg-0" lg="5">
                                <div>
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-warning">
                                        {/*<i className="ni ni-building text-primary" />*/}
                                        <i className="fa fa-desktop" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <h4 className="display-3 text-white">Predlab Web Console</h4>
                                <p className="lead text-white">
                                    <p>
                                        Not every team member has access to an IDE, and they don't need to.</p>
                                    <p>With Predlab Web Console, team members can access and manage their project's
                                        documentation from any web browser.</p>
                                </p>
                                <Button
                                    className="btn-white mt-4"
                                    color="default"
                                    target="_blank"
                                    href="https://predlab.com/guide/code-annotations/#on-predlab-web-console"
                                >
                                    Learn More
                                </Button>
                            </Col>
                        </Row>

                        {/*<img*/}
                        {/*    alt="..."*/}
                        {/*    style={{marginBottom: "30px"}}*/}
                        {/*    className=" img-fluid rounded shadow-lg overflow-hidden img-console"*/}
                        {/*    src={require("assets/img/screen_console_flags.png")}*/}
                        {/*/>*/}
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-lg">
                    <Container>
                        <Row className="justify-content-center text-center mb-lg">
                            <Col lg="8">
                                <h2 className="display-3"><i className="fa fa-lock"
                                                             aria-hidden="true"></i> CONFIDENTIALITY</h2>
                                <p className="lead text-muted">
                                    Predlab is a platform that allows you to share annotations with your team. It does
                                    not sync your code base nor your projects data.
                                </p>
                                <Button
                                    className="btn-primary mt-4"
                                    color="default"
                                    target="_blank"
                                    href="https://predlab.com/guide/confidentiality/"
                                >
                                    PRIVACY FAQ
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-5 mb-lg-0" lg="4" md="6">
                                <div className="px-4">
                                    <img
                                        alt="..."
                                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                        src={require("assets/img/abstract_monitor_code.jpg")}
                                        style={{width: "200px"}}
                                    />
                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">NO code syncing</span>
                                            <small className="h6 text-muted">Predlab does not replace your VCS. It does
                                                not sync/checkout your code.</small>
                                        </h5>

                                    </div>
                                </div>
                            </Col>
                            <Col className="mb-5 mb-lg-0" lg="4" md="6">
                                <div className="px-4">
                                    <img
                                        alt="..."
                                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                        src={require("assets/img/screen_offline_home.png")}
                                        style={{width: "200px"}}
                                    />
                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">Sandbox Mode</span>
                                            <small className="h6 text-muted">
                                                A solo mode. For a completely offline workstations. Where annotations
                                                are stored locally.
                                            </small>
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            {/*<Col className="mb-5 mb-lg-0" lg="4" md="6">*/}
                            {/*    <div className="px-4">*/}
                            {/*        <img*/}
                            {/*            alt="..."*/}
                            {/*            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"*/}
                            {/*            src={require("assets/img/screen_optin_check.png")}*/}
                            {/*            style={{width: "200px"}}*/}
                            {/*        />*/}
                            {/*        <div className="pt-4 text-center">*/}
                            {/*            <h5 className="title">*/}
                            {/*                <span className="d-block mb-1">Opt-in for code snippets</span>*/}
                            {/*                <small className="h6 text-muted">An individual opt-in option have to be*/}
                            {/*                    checked to include a code snippet in an annotation.</small>*/}
                            {/*            </h5>*/}

                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Col>*/}

                            <Col className="mb-5 mb-lg-0" lg="4" md="6">
                                <div className="px-4">
                                    <img
                                        alt="..."
                                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                        src={require("assets/img/screen_data_control.png")}
                                        style={{width: "200px"}}
                                    />
                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">Backups and Deletion</span>
                                            <small className="h6 text-muted">
                                                Predlab lets you export backups of your workstations. You can always
                                                delete the data you don't need.
                                            </small>
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section bg-gradient-info">
                    <Container>
                        <Row className="text-center justify-content-center">
                            <Col lg="10">
                                <h2 className="display-3 text-white">Get Started In 3 Steps!</h2>
                                <p className="lead text-white">
                                    You are a few clicks away from taking your team work to the next level!
                                </p>
                            </Col>
                        </Row>
                        <Row className="row-grid mt-5">
                            <Col lg="4">
                                <div
                                    className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle">
                                    <i className="fa fa-download"/>
                                </div>
                                <h5 className="text-white mt-3">Install Predlab</h5>
                                <p className="text-white mt-3">
                                    Installing Predlab plugin from JetBrains Plugin Repository is one click away!
                                </p>
                            </Col>
                            <Col lg="4">
                                <div
                                    className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle ">
                                    <i className="fa fa-plus"/>
                                </div>
                                <h5 className="text-white mt-3">Create or Join your first workspace</h5>
                                <p className="text-white mt-3">
                                    Create your first workspace or join your team's workspaces.
                                </p>
                            </Col>
                            <Col lg="4">
                                <div
                                    className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle">
                                    <i className="fa fa-comments"/>
                                </div>
                                <h5 className="text-white mt-3">Collaborate!</h5>
                                <p className="text-white mt-3">
                                    Instantly share, get access to content, and stay in sync with your team activity.
                                </p>
                            </Col>

                        </Row>


                        <Row className="row-grid justify-content-center">
                            <Col className="text-center" lg="8">
                                <Button
                                    style={{fontSize: "1.5rem"}}
                                    className="btn-success btn-icon mb-5 mb-sm-0"
                                    color="success"
                                    target="_blank"
                                    href="https://predlab.com/guide/getting-started/#install-predlab-from-repository"
                                    size="lg"
                                >
                                    <span className="btn-inner--text">GIVE IT A TRY</span>
                                </Button>{" "}
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
            </main>
            <PredFooter/>
        </>
    );
}


export default LandingPredlab;
