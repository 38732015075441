
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/css/custom-predlab.css";

import Index from "views/Index.js";

import Login from "views/examples/Login.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import LandingPredlab from "./views/LandingPredlab";
import Landing from "./views/examples/Landing";
import Pricing from "./views/Pricing";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <LandingPredlab {...props} />} />
      <Route
        path="/landing-page"
        exact

        render={props => <Landing {...props} />}
      />
      {/*<Route path="/login-page" exact render={props => <Login {...props} />} />*/}

        <Route
            path="/pricing"
            exact
            render={props => <Pricing {...props} />}
        />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
